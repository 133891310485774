import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// ルートがロードされたタイミングでコンポーネントの読込を開始する
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue')
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/Work.vue')
  },
  {
    path: '/access',
    name: 'Access',
    component: () => import('../views/Access.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
