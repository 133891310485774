<template>
  <v-app>
    <!-- タイトルヘッダー -->
    <v-toolbar
      id="header"
      height="180"
      color="white"
      alt="header"
      src="@/assets/images/header.jpg"
    >
      <img
        id="logo-pc"
        src="@/assets/images/pc_logo.png"
        alt="徳永工務店パソコン用ロゴ"
        align="left"
      />
      <img
        id="logo-sd"
        src="@/assets/images/sd_logo.png"
        alt="徳永工務店スマートデバイス用ロゴ"
        align="left"
      />
    </v-toolbar>
    <!-- メニューバー -->
    <v-toolbar id="menu-tabs" height="80" color="theme">
      <v-tabs v-model="tabmodel" centered icons-and-text dark>
        <v-tabs-slider  color="subtheme" />

        <v-tab @click="$router.push({ name: 'Home' })">
          ホーム
          <v-icon color="white">mdi-home-outline</v-icon>
        </v-tab>
        <v-tab @click="$router.push({ name: 'Company' })">
          会社概要
          <v-icon color="white">mdi-office-building-outline</v-icon>
        </v-tab>
        <v-tab @click="$router.push({ name: 'Work' })">
          事業内容
          <v-icon color="white">mdi-account-hard-hat</v-icon>
        </v-tab>
        <v-tab @click="$router.push({ name: 'Access' })">
          アクセス
          <v-icon color="white">mdi-map-marker</v-icon>
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- メインページ -->
    <v-main id="main" class="mb-5">
      <router-view />
    </v-main>

    <!-- フッター -->
    <v-footer padless>
      <v-row justify="center" no-gutters>
        <v-col class="theme py-4 text-center white--text" cols="12"
          >有限会社&nbsp;徳永工務店&nbsp;&nbsp;TEL&nbsp;:&nbsp;0955-52-6733</v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    tabmodel: null,
  }),

  updated() {
    switch (this.$route.name) {
      case 'Company':
        this.tabmodel = 1;
        break;
      case 'Work':
        this.tabmodel = 2;
        break;
      case 'Access':
        this.tabmodel = 3;
        break;
      default:
        this.tabmodel = 0;
        break;
    }
  }
};
</script>

<style>
#header {
  max-height: 11rem;
}
/* メニューバー固定 */
#menu-tabs {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 200;
  height: 80px;
  max-height: 80px;
}

#logo-pc {
  display: block !important;
  height: 70%;
  width: auto;
}
#logo-sd {
  display: none !important;
}

@media only screen and (max-width: 480px) {
  #logo-pc {
    display: none !important;
  }
  #logo-sd {
    display: block !important;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0.5rem;
  }
}
</style>
